/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useAuth } from '../../auth/auth';
import { removeUser } from '../../auth/actions';
import './Header.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const {state, dispatch} = useAuth();
  const [avatarColor, setAvatarColor] = useState<any>();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const colors = [
    '#444c5c', '#ce5a57', '#78a5a3', '#e1b16a', '#aaaaaa',
    '#efc070', '#A2C4C9', '#8E7CC3', '#D5A6BD', '#EA9999'
  ];

  const getInitials = () => {
    return `${state.user?.name?.[0]}${state.user?.surname?.[0]}`;
  };

  const openAvatar = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    dispatch(removeUser())
    localStorage.removeItem('avatarColor')
  }

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  useEffect(() => {
    let storedColor = localStorage.getItem('avatarColor');
    if (!storedColor) {
      storedColor = getRandomColor();
      localStorage.setItem('avatarColor', storedColor);
    }
    setAvatarColor(storedColor);
  }, []);

  return (
    <div className='main-header'>
      <Link to={'/pace-team/resourcing/resource-tracker'} className='logo'>PACE</Link>
      <div>
        <Tooltip title="">
          <IconButton onClick={openAvatar}>
            <Avatar style={{fontSize: 16, backgroundColor: avatarColor}} sx={{height: 35, width: 35}}>
              {getInitials()}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            sx={{width: 200}} 
            onClick={() => { handleClose(); logOut(); }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;