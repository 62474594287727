/* eslint-disable react-hooks/exhaustive-deps */
import './Header.css';
import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Tooltip,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    useMediaQuery,
} from "@mui/material";
import { Link, useLocation  } from 'react-router-dom';
import { useAuth } from '../../../../auth/auth';
import { removeUser } from '../../../../auth/actions';

type Props = {
    setDate: React.Dispatch<React.SetStateAction<any>>
    week: number
    setWeek: React.Dispatch<React.SetStateAction<number>>
    year: number
    setYear: React.Dispatch<React.SetStateAction<number>>
};

const Header = ({ setDate, week, setWeek, year, setYear }: Props) => {
    const {state, dispatch} = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [avatarColor, setAvatarColor] = useState<any>();
    const open = Boolean(anchorEl);
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width:1245px)');

    const colors = [
        '#444c5c', '#ce5a57', '#78a5a3', '#e1b16a', '#aaaaaa',
        '#efc070', '#A2C4C9', '#8E7CC3', '#D5A6BD', '#EA9999'
    ];

    const logOut = () => {
        dispatch(removeUser());
        localStorage.removeItem('avatarColor');
    }

    const getRailwayYearStartDate = (year: number): Date => {
        const aprilFirst = new Date(year, 3, 1);
        const dayOfWeek = aprilFirst.getDay();
        const daysBeforeSaturday = (dayOfWeek + 1) % 7;
        return new Date(year, 2, 31 - daysBeforeSaturday);
    };

    const getWeekStartDate = (week: number, year: number): Date => {
        const railwayYearStartDate = getRailwayYearStartDate(year);
        const startDate = new Date(railwayYearStartDate);
        startDate.setDate(railwayYearStartDate.getDate() + week * 7);
        setDate(startDate);
        return startDate;
    };

    const handleSubmit = () => {
        if (week && year) {
            if (week < 1 || week > 52 || year < 2024 || year > 2026) {
                alert('Invalid week or year');
                return;
            }
            getWeekStartDate(week, year);
        } else {
            alert('Please fill in both fields');
        }
    };

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const getInitials = () => {
        return `${state.user?.name?.[0]}${state.user?.surname?.[0]}`;
    };

    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    useEffect(() => {
        if (week && year) {
            getWeekStartDate(week, year);
        }

        let storedColor = localStorage.getItem('avatarColor');
        if (!storedColor) {
            storedColor = getRandomColor();
            localStorage.setItem('avatarColor', storedColor);
        }
        setAvatarColor(storedColor);
    }, []);

    return (
        <div className="header">
            <div className='form'>
                <TextField
                    label="Week"
                    type="number"
                    size="small"
                    value={week}
                    onChange={(e) => setWeek(parseInt(e.target.value, 10))}
                    style={{margin: '15px', width: '30%'}}
                    inputProps={{min: 1, max:  52}}
                />
                <TextField
                    label="Year"
                    type="number"
                    size="small"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value, 10))}
                    style={{margin: '15px', width: '30%'}}
                    inputProps={{min: 2024}}
                />
                <Button
                    variant="outlined"
                    onClick={handleSubmit}
                    style={{margin: '17px', width: '17%', borderColor: '#b4b4b4', color: '#e39865'}}
                >
                    Submit
                </Button>
            </div>
            <div className='link' style={{textDecoration: 'none'}}>
                {isMobile ? (
                    <div style={{marginRight: 50}}>
                        <Tooltip title="">
                            <IconButton onClick={handleClick}>
                                <Avatar style={{fontSize: 16, backgroundColor: avatarColor}} sx={{height: 35, width: 35}}>
                                    {getInitials()}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker' && 'focused'}`}>Resource Tracker</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/table?year=${year}&week=${week}`} className={`rt ${location.pathname === '/table' && 'focused'}`}>Data Table</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/workers?year=${year}&week=${week}`} className={`rt ${location.pathname === '/workers' && 'focused'}`}>Workers</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/graph?year=${year}&week=${week}`} className={`rt ${location.pathname === '/graph' && 'focused'}`}>Graphs</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/maps?year=${year}&week=${week}`} className={`rt ${location.pathname === '/maps' && 'focused'}`}>Maps</Link>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <Link to={`/pace-team/resourcing/resource-tracker/on-call?year=${year}&week=${week}`} className={`rt ${location.pathname === '/onCall' && 'focused'}`}>On Call</Link>
                            </MenuItem>
                            <MenuItem
                                sx={{width: 200, margin: '0 18px'}} 
                                onClick={() => { handleClose(); logOut(); }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                ) : (
                    <>
                        <Link to={`/pace-team/resourcing/resource-tracker?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker' && 'focused'}`}>Resource Tracker</Link>
                        <Link to={`/pace-team/resourcing/resource-tracker/table?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/table' && 'focused'}`}>Data Table</Link>
                        <Link to={`/pace-team/resourcing/resource-tracker/workers?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/workers' && 'focused'}`}>Workers</Link>
                        <Link to={`/pace-team/resourcing/resource-tracker/graph?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/graph' && 'focused'}`}>Graphs</Link>
                        <Link to={`/pace-team/resourcing/resource-tracker/maps?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/maps' && 'focused'}`}>Maps</Link>
                        <Link to={`/pace-team/resourcing/resource-tracker/on-call?year=${year}&week=${week}`} className={`rt ${location.pathname === '/pace-team/resourcing/resource-tracker/on-call' && 'focused'}`}>On call</Link>
                        <Tooltip title="">
                            <IconButton onClick={handleClick}>
                                <Avatar style={{fontSize: 16, backgroundColor: avatarColor}} sx={{height: 35, width: 35}}>
                                    {getInitials()}
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem
                                sx={{width: 200}} 
                                onClick={() => { handleClose(); logOut(); }}
                            >
                                Logout
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </div>
        </div>
    )
}

export default Header;
