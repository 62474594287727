/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarFilterButton } from '@mui/x-data-grid';
import Header from '../../components/Header/Header';
import { useSearchParams } from 'react-router-dom';
import { getWeeklyWorkersList } from '../../../../api/apiCall';
import { useAuth } from '../../../../auth/auth';
import Modal from 'react-modal';
import './Workers.css'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHourglassEnd, faHourglassStart, faSignsPost, faUser } from '@fortawesome/free-solid-svg-icons';

// const CustomTooltip = styled(({ className, ...props }: any) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(() => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#333',
//     color: '#fff',
//     width :'1000px',
//     fontSize: '14px',
//     borderRadius: '4px',
//     padding: '8px 16px',
//     whiteSpace: 'pre-line'
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: '#333',
//   },
// })); 

export default function DataGridDemo() {
  const {state} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(null);
  const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
  const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
  const [workersList, setWorkersList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [shiftDetails, setshiftDetails] = useState<any>([]);

  const getWorkersList = async () => {
    try {
      const response = await getWeeklyWorkersList(state.user?.token, week, year);
      setWorkersList(response?.workers)
    } catch (error) {
      console.log(error)
    }
  }

  const processedWorkersList = workersList.map((worker: any) => {
    const shiftDetails = worker.shifts.map((shift: any) => shift);
    return {
      ...worker,
      shiftDetails,
      details: 'WKD ...',
    };
  });

  const getShiftDetails = (shiftDetails: any) => {
    setshiftDetails(shiftDetails);
    setOpen(true)
  }
  
  const columns = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      width: 320,
    },
    {
      field: 'pts',
      headerName: 'PTS',
      width: 325,
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 313,
    },
    {
      field: 'shiftCount',
      headerName: 'Total Shifts',
      width: 256,
    },
    {
      field: 'details',
      headerName: 'Shift Details',
      description: 'This column has a value getter and is not sortable.',
      width: 320,
      renderCell: (params: any) => (
        // <CustomTooltip title={params.row.shiftDetails} arrow>
        //   <span>{params.value}</span>
        // </CustomTooltip>
        <div onClick={() => getShiftDetails(params.row.shiftDetails)}>{params.value}</div>
      ),
    },
  ];

  const Toolbar = () => {
    return (
      <div>
        <GridToolbarFilterButton />
      </div>
    );
  };

  useEffect(() => {
    setSearchParams({ week, year });
    getWorkersList();
  }, [date]);

  return (
    <>
      <Header
        setDate={setDate}
        week={week}
        setWeek={setWeek}
        year={year}
        setYear={setYear}
      />
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        className='modal'
        style={{
          overlay: {
              zIndex: 10000,
              backgroundColor: 'transparent',
              transition: 'none',
              width: '100%',
          }
        }}
      >
        <div style={{whiteSpace: 'pre-line'}}>
          {shiftDetails.map((shift: any) => (
            <SimpleTreeView key={shift.jobDate}>
              <TreeItem itemId="day" label={`${shift.whatsAppName} - ${format(shift.jobDate, 'dd/MM/yyyy')}`}>
                <div>
                  <h4>Project: </h4>
                  <div>{shift.project}</div>
                  <div className="line"></div>
                  <h4>Details: </h4>
                  <div>{shift.jobDescription ? shift.jobDescription : 'Civil Work'}</div>
                  <div className="line marginBottom"></div>
                  <div className="details">
                    <FontAwesomeIcon color='#1e3050' icon={faUser} />  
                    <div style={{ marginLeft: '17px' }}>
                      <div className="text">Work Role</div>
                      <div>{shift.workRole}</div>
                    </div>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon color='#1e3050' icon={faCalendar} />  
                    <div style={{ marginLeft: '17px' }}>
                      <div className="text">Shift Date</div>
                      <div>{format(shift.jobDate, 'EEEE')} {format(shift.jobDate, 'dd/MM/yyyy')}</div>
                    </div>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon color='#1e3050' icon={faHourglassStart} />  
                    <div style={{ marginLeft: '17px' }}>
                      <div className="text">Start Time</div>
                      <div>{format(shift.startTime, 'hh:mm')}</div>
                    </div>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon color='#1e3050' icon={faHourglassEnd} />  
                    <div style={{ marginLeft: '17px' }}>
                      <div className="text">End Time</div>
                      <div>{format(shift.endTime, 'hh:mm')}</div>
                    </div>
                  </div>
                  <div className="details">
                    <FontAwesomeIcon color='#1e3050' icon={faSignsPost} />  
                    <div style={{ marginLeft: '17px' }}>
                      <div className="text">Location</div>
                      <div>{shift.location} - {shift.postCode}</div>
                    </div>
                  </div>
                  <div className="line marginBottom"></div>
                </div>
              </TreeItem>          
            </SimpleTreeView>
          ))}
        </div>
      </Modal>
      <Box sx={{ height: "750px", width: '80%' }} marginTop={3} marginLeft={25}>
        <DataGrid
          rows={processedWorkersList}
          columns={columns}
          disableRowSelectionOnClick
          slots={{ toolbar: Toolbar }}
          getRowId={(row: any) => row.workerId}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 14,
              },
            },
          }}
          pageSizeOptions={[14]}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#ffa970",
              color: "#000",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            '& .disable-columns': {
              backgroundColor: '#f5f5f5',
            },
          }}
        />
      </Box>
    </>
  );
}
