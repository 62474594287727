import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { addDays } from "date-fns";
import { DateRangePicker } from 'react-date-range';
import { generatePayrollReport } from "../../../api/apiCall";
import { useAuth } from "../../../auth/auth";
import './WeeklyReport.css'

const WeeklyReport = () => {
    const {state} = useAuth();
    const [loading, setLoading] = useState(false)
    const [dates, setState] = useState<any>([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
    ]);

    const getWeeklyReport = async () => {
        setLoading(true);
        const response = await generatePayrollReport(
            state.user?.token,
            dates[0].startDate,
            dates[0].endDate
        );
        if(response) {
            setLoading(false);
        }else { 
            setLoading(false);
        }
    }

    return (
        <>
            <h2 className="h2-element">Download Payroll Report</h2>
            <div className="box-div">
                <div className="div-range-date">
                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={dates}
                        direction="horizontal"
                    />
                </div>
                <div className="export-button">
                    <Button
                        variant="contained"
                        disabled={loading}
                        style={{
                            margin: "20px",
                            color: "white",
                            height: "40px",
                            width: "250px",
                            boxShadow: "none",
                        }}
                        onClick={getWeeklyReport}>
                            {loading? <CircularProgress size={25} style={{color: 'white'}} /> : 'Export to Excel' }
                    </Button>    
                </div>
            </div>
        </>
    )
}

export default WeeklyReport;
