import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Autocomplete, Button, MenuItem, Select, TextField } from "@mui/material";
import { getCompetencies, getExperiences } from "../../../api/apiCall";
import './WorkforceMap.css'
import { useAuth } from "../../../auth/auth";
import { useForm } from "react-hook-form";

const WorkforceMap = () => {
    const {state} = useAuth()
    const [competencies, setComptenecies] = useState<any>([]);
    const [experiences, setExperiences] = useState<any>([]);
    const {register, handleSubmit} = useForm();

    const getAllCompetenecies = async () => {
        try {
            const response = await getCompetencies(state.user?.token);
            setComptenecies(response)
        } catch (error) {
            console.log(error)
        }
    }

    const getAllExperiences = async () => {
        try {
            const response = await getExperiences(state.user?.token);
            setExperiences(response)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = (data: any) => {
        console.log(data)
    }
    const workers = [
        {
          name: "Earl Kearney",
          pts: 1403782,
          phone: "7951331417",
          postCode: "NW6 2NR",
        },
        {
          name: "Ionut Ionascu",
          pts: 669000,
          phone: "7446982719",
          postCode: "HA3 0XH",
        },
      ];
    useEffect(() => {
        getAllCompetenecies();
        getAllExperiences();
    }, [])

    return (
        <>
            <div className="filter-div">
                <TextField
                    label="Post Code"
                    type="text"
                    size="small"
                    {...register("postCode")}
                    style={{width: "18%", margin: 10}}
                />
                <TextField
                    label="Distance in Km"
                    type="number"
                    size="small"
                    {...register("distanceInKm")}
                    style={{width: "18%", margin: 10}}
                />
                <Autocomplete
                    size="small"
                    style={{ margin: 10, width: '22.5%' }}
                    options={competencies}
                    getOptionLabel={(option: any) => `${option.skillName || ""}`}
                    {...register("competency")}
                    renderInput={(params) =>
                        <TextField
                            label='Competency'
                            {...register("competency")}
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (event) => {
                                    if (event.key === ' ') {
                                        event.stopPropagation();
                                    }
                                },
                            }}
                        />
                    }
                />
                <Autocomplete
                    size="small"
                    style={{ margin: 10, width: '22.5%' }}
                    options={experiences}
                    getOptionLabel={(option: any) => `${option.experienceName || ""}`}
                    {...register("experienceType")}
                    renderInput={(params) =>
                        <TextField
                            label='Experience Type'
                            {...register("experienceType")}
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                                onKeyDown: (event) => {
                                    if (event.key === ' ') {
                                        event.stopPropagation();
                                    }
                                },
                            }}
                        />
                    }
                />
                <Button onClick={handleSubmit(onSubmit)} size="large" style={{width: '18%', height: 40, margin: 10, boxShadow: 'none'}} variant="contained">Filter</Button>
                <Button onClick={handleSubmit(onSubmit)} size="large" style={{width: '18%', height: 40, margin: 10, boxShadow: 'none'}} variant="contained">Search by Region</Button>

            </div>
            <div className="main-map-div">
                <div className="map-div">
                    <MapContainer center={[51.505, -0.09]} zoom={8} style={{ height: '75vh', width: '90vw', zIndex: 1 }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </MapContainer>
                </div>
                <div className="list-workers">
                    <div className="container-2">
                        <h1 className="title">
                            List of workers - total <span className="total">184</span>
                        </h1>
                        <div className="workers-list">
                            {workers.map((worker, index) => (
                                <div key={index} className="worker-card">
                                    <h2 className="worker-name">{worker.name}</h2>
                                    <p className="worker-detail">
                                        <strong>PTS:</strong> {worker.pts}
                                    </p>
                                    <p className="worker-detail">
                                        <strong>Phone:</strong> {worker.phone}
                                    </p>
                                    <p className="worker-detail">
                                        <strong>Post Code:</strong> {worker.postCode}
                                    </p>
                                    <p className="worker-details-link">
                                        <a href="/pace-team/resourcing/regions-map">Details</a>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkforceMap;
