import './resource.css'
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {Dropdown} from 'antd';
import {Button} from '@mui/material';
import Shift from '../../components/Shift/Shift';

const ResourceTracker = ({
  days,
  gridData,
  handleCellClick,
  handleDragStart,
  handleDrop,
  handleDragOver,
  items,
  handleMenuClick,
  handleRightClick,
  dragStatus,
  handleFlip,
  flippedCells,
  length,
  setLength,
  duplicateMode,
  disabledCells,
}: any) => {
  const addNewRow = () => {
    setLength((prevLength: any) => {
      const newLength: any = prevLength + 1;
      return newLength;
    });
  };

  return (
    <>
      <div className="grid-container">
        {days.map((day: any) => (
          <div
            key={day}
            className="grid-header"
            style={{backgroundColor: duplicateMode? '#ca8659' : '#e39865'}}
          >
            <div>{day.dayOfWeek}</div>
            <div>{day.date}</div>
          </div>
        ))}
        {Array.from({ length: length }).map((_, rowIndex) =>
          days.map((day: any, colIndex: number) => (
            <Dropdown
              menu={{
                items,
                onClick: handleMenuClick,
              }}
              trigger={[gridData[`${colIndex}-${rowIndex}`] && "contextMenu"]}
            >
              <div
                key={`${colIndex}-${rowIndex}`}
                className={`grid-item ${
                  dragStatus[`${colIndex}-${rowIndex}`] === "success"
                    ? "success-style"
                    : dragStatus[`${colIndex}-${rowIndex}`] === "failed"
                    ? "failed-style"
                    : ""
                  }`
                }
                onDrop={(event) => handleDrop(colIndex, rowIndex, event, day.day)}
                onDragOver={handleDragOver}
                onClick={() => handleCellClick(colIndex, rowIndex, day.date, day.day)}
                onContextMenu={(event) =>
                  handleRightClick(colIndex, rowIndex, event)
                }
                style={{ cursor: duplicateMode ? 'grab' : 'default', 
                  backgroundColor: disabledCells.some((cells: any) => 
                    colIndex === cells.colIndex && rowIndex === cells.rowIndex
                  ) ? '#f5f5f5' : '',
                  border: disabledCells.some((cells: any) => 
                    colIndex === cells.colIndex && rowIndex === cells.rowIndex
                  ) ? '2px solid #A2C4C9' : '',
                }}
              >
                {!gridData[`${colIndex}-${rowIndex}`] && (
                  <div style={{textAlign: "right"}}>
                    {rowIndex + 1}, {colIndex + 1}
                  </div>
                )}

                {gridData[`${colIndex}-${rowIndex}`] && (
                  <>
                    <div
                     key={colIndex}
                      draggable
                      onDragStart={(event) =>
                        handleDragStart(colIndex, rowIndex, event)
                      }
                      onClick={() => handleFlip(colIndex, rowIndex)}
                    >
                      <Shift
                        gridData={gridData}
                        colIndex={colIndex}
                        rowIndex={rowIndex}
                        day={day}
                        flippedCells={flippedCells}
                      /> 
                    </div>
                  </>
                )}
              </div>
            </Dropdown>
          ))
        )}
      </div>
      <div className='button'>
        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon
              icon={faPlus}
              size={'5x'}
              color='#e39865'
            />
          }
          onClick={() => addNewRow()}
          size='large'
          style={{
            width: "4%",
            borderColor: 'black',
            color: 'black'
          }}>Add New Row
        </Button>
      </div>
    </>
  );
};

export default ResourceTracker