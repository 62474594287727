import React, { useState } from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAddressBook,
    faAddressCard,
    faBookmark,
    faBorderAll,
    faCar,
    faDatabase,
    faRectangleList,
    faUserGroup,
    faBars,
    faCircleDollarToSlot,
    faCheckDouble
} from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header/Header';
import './Layout.css'

const Layout = ({ children }: { children: React.ReactNode }) => {
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const menuItemStyle = {
        color: '#012970',
        fontWeight: 500
    };

    return (
        <>
            {!location.pathname.includes('/resource-tracker') && <Header />}
            <div className='layout'>
                <Sidebar collapsed={collapsed} backgroundColor='white'>
                    <Menu>
                        <MenuItem
                            style={menuItemStyle}
                            onClick={toggleSidebar}
                            icon={<FontAwesomeIcon icon={faBars} />}
                        />
                        <SubMenu
                            label="Collaborations"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faAddressBook} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/collaborations/clients'} />}
                            >
                                Clients
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/collaborations/projects'} />}
                            >
                                Projects
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="User Management"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faAddressCard} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/user-management/manage-users'} />}
                            >
                                Manage Users
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/user-management/manage-workers'} />}
                            >
                                Manage Workers
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Resourcing"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faRectangleList} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/resourcing/resource-tracker'} />}
                            >
                                Resource Tracker
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/resourcing/workforce-map'} />}
                            >
                                Workforce Map
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={"/pace-team/resourcing/rail-calendar"} />}
                            >
                                Rail Calendar
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={"/pace-team/resourcing/live-tracker"} />}
                            >
                                Live Tracker
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Groups"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faUserGroup} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/groups/manage-groups'} />}
                            >
                                Manage Groups
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Roles/Competencies/Experiences"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faBorderAll} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/roles/manage-roles'} />}
                            >
                                Manage Roles
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/competencies'} />}
                            >
                                Competencies
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/experiences'} />}
                            >
                                Experiences
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Tickets"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faBookmark} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/skills/manage-tickets'} />}
                            >
                                Manage Tickets
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Payroll"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faCircleDollarToSlot} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/payroll/payment-method'} />}
                            >
                                Payment Methods
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/payroll/weekly-report'} />}
                            >
                                Weekly Reports
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="RT Data"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faDatabase} color='#949fc1' />}
                            component={<Link to={'/pace-team/rt-data'} />}
                        >
                        </SubMenu>
                        <SubMenu
                            label="Drivers"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faCar} color='#949fc1' />}
                        >
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/drivers/add-driver'} />}
                            >
                                Add New Driver
                            </MenuItem>
                            <MenuItem
                                style={{ marginLeft: 25, ...menuItemStyle }}
                                component={<Link to={'/pace-team/drivers/manage-drivers'} />}
                            >
                                Manage Drivers
                            </MenuItem>
                        </SubMenu>
                        <SubMenu
                            label="Fatigues"
                            style={menuItemStyle}
                            icon={<FontAwesomeIcon icon={faCheckDouble} color='#949fc1' />}
                            component={<Link to={'/pace-team/fatigue'} />}
                        >
                        </SubMenu>
                    </Menu>
                </Sidebar>
                <div style={{flexGrow: 1}}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Layout;