/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Header from "../../components/Header/Header";
import './Graph.css'
import { useSearchParams } from "react-router-dom";
import { getClientJobsStatistics, getClientsPieData, getJobsStatusPieData } from "../../../../api/apiCall";
import { useAuth } from "../../../../auth/auth";
import { OrgChart } from "../OrgChart/OrgChart";

export const options = {
  title: 'Shifts per Client',
  titleTextStyle: {
    fontSize: 20,
  },
  vAxis: { minValue: 0 },
  curveType: "function",
  chart: {
    title: "Company Performance",
  },
  // legend: { position: "bottom", maxLines: 2 },
};

export const optionChart = {
  title: 'Shifts per Client',
  titleTextStyle: {
    fontSize: 20,
  },
}

export const optionsPieChart = {
  legend: "none",
  titleTextStyle: {
    fontSize: 20,
  },
  vAxis: { minValue: 0 },
  pieSliceText: "label",
  curveType: "function",
  title: "Shifts per Client",
}

export const optionsStatusPieChart = {
  title: 'Status Distribution',
  titleTextStyle: {
    fontSize: 18,
  },
  legend: "none",
  pieSliceText: "label",
  colors: ['#74C365','#F7DE3A', '#FF0000',],
  pieHole: 0.6
}

export function Graph() {
  const {state} = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(null);
  const [week, setWeek] = useState<any>(searchParams.get('week') || 1);
  const [year, setYear] = useState<any>(searchParams.get('year') || 2024);
  const [clientPieData, setClientPieData] = useState([]);
  const [statusPieData, setStatusPieData] = useState([]);
  const [clientJobsStatistics, setClientJobsStatistics] = useState<any>([]);

  const getClientsPieDatas = async () => {
    try {
      const response = await getClientsPieData(state.user?.token, week, year)
      setClientPieData(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getJobsStatusPieDatas = async () => {
    try {
      const response = await getJobsStatusPieData(state.user?.token, week, year)
      setStatusPieData(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const getClientJobStatistics = async () => {
    try {
      const response = await getClientJobsStatistics(state.user?.token, week, year)
      setClientJobsStatistics(response?.data)
    } catch (error) {
      console.log(error)
    }
  }

  const pieData: any = clientPieData.map(({ name, value }) => [name, value]);
  pieData.unshift(["Clients", "Shifts"]);

  const days = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

  const header = ["Days", ...clientJobsStatistics.map((item: any) => item.name)];

  const rows = days.map((day, dayIndex) => {
    const row = [day];
    clientJobsStatistics.forEach((item: any) => {
      row.push(item.value[dayIndex]);
    });
    return row;
  });

  const data = [header, ...rows];

  const pieSatusData: any = statusPieData.map(({ name, value }) => [name, value]);
  pieSatusData.unshift(["Status", ""]);
  
  useEffect(() => {
    getClientsPieDatas();
    getJobsStatusPieDatas();
    getClientJobStatistics();
    setSearchParams({ week, year });
  }, [date]);

  return (
    <>
      <Header
        setDate={setDate}
        week={week}
        setWeek={setWeek}
        year={year}
        setYear={setYear}
      />
      <div className="charts">
        <div className="pie-chart">
          <Chart
            chartType="PieChart"
            width="100%"
            height="550px"
            data={pieData}
            options={optionsPieChart}
          />
        </div>
        <div className="line-chart">
          <Chart
            chartType="LineChart"
            width="100%"
            height="550px"
            data={data}
            options={options}
          />
        </div>
      </div>
      <div className="charts">
        <div className="column-chart">
          <Chart
            chartType="ColumnChart"
            width="100%"
            height="550px"
            data={data}
            options={optionChart}
          />
        </div>
        <div className="pie-chart-status">
          <Chart
            chartType="PieChart"
            width="100%"
            height="550px"
            data={pieSatusData}
            options={optionsStatusPieChart}
          />
        </div>
      </div>
      <div style={{marginBottom: 100, marginTop: 100}}>
        <OrgChart week={week} year={year} />
      </div>
    </>
  );
}
